import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import apiSlice from './apiSlice';

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		api: apiSlice,
	},
});
