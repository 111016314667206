// Importing necessary libraries and components
import Link from "next/link";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { buyModalHide } from "../../redux/counterSlice";
import { Confirm_checkout } from "../metamask/Metamask";
import Image from "next/image";

// Base URL for images
export const baseURL = `${process.env.BASE_URL}/`;

// BuyModal component
const BuyModal = () => {
  // Using Redux state for buyModal and buyModalData
  const { buyModal, buyModalData } = useSelector((state) => state.counter);
  const dispatch = useDispatch();

  // Item data from the Redux store
  const item = buyModalData;

  // Component return
  return (
    <div>
      {/* Buy Now Modal */}
      <div className={buyModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNowModalLabel">
                Complete checkout
              </h5>
              {/* Close button for the modal */}
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(buyModalHide())}
              >
                {/* SVG for the close button */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            <div className="modal-body p-6">
              {/* ... */}
              {/* Item details and price */}
              <div className="dark:border-jacarta-600 border-jacarta-100 relative flex items-center border-t border-b py-4">
                {/* ... */}
                {/* Item image */}
                <figure className="mr-5 self-start">
                  <Image
                    width={150}
                    height={150}
                    src={baseURL + item.imageUrl}
                    alt="avatar 2"
                    className="rounded-2lg"
                    loading="lazy"
                  />
                </figure>
                {/* ... */}
                {/* Item name and creator */}
                <div>
                  {/* ... */}
                  <h3 className="font-display text-jacarta-700 mb-1 text-base font-semibold dark:text-white">
                    {item?.itemName}
                  </h3>
                  {/* ... */}
                  <span className="dark:text-jacarta-300 text-jacarta-500 mr-1 block text-sm">
                    Creator: {item?.creator?.username}
                  </span>
                  {/* ... */}
                </div>
                {/* ... */}
                {/* Item price */}
                <div className="ml-auto">
                  {/* ... */}
                  <div className="dark:text-jacarta-300 text-right text-sm">
                    ${item?.priceUsd}
                  </div>
                </div>
              </div>
              {/* ... */}
              {/* Total price */}
              <div className="dark:border-jacarta-600 border-jacarta-100 mb-2 flex items-center justify-between border-b py-2.5">
                {/* ... */}
                <div className="ml-auto">
                  {/* ... */}
                  <div className="dark:text-jacarta-300 text-right">
                    ${item?.priceUsd}
                  </div>
                </div>
              </div>
              {/* ... */}
              {/* Terms and conditions */}
              <div className="mt-4 flex items-center space-x-2">
                {/* ... */}
                <label
                  htmlFor="buyNowTerms"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By checking this box, I agree to {"Impactoverse's"}{" "}
                  <Link href="/tarms" className="text-accent">
                    Terms of Service
                  </Link>
                </label>
              </div>
            </div>
            {/* Modal Footer */}
            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                {/* Confirm checkout button */}
                <Confirm_checkout />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Exporting the BuyModal component
export default BuyModal;
