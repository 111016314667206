import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{`${title} | Impactoverse Digital Impact Ecosystem`}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Impactoverse ",
  keyword:
    "bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, Impact DOT, Impact DOT marketplace, Impact DOT next js, Impact DOT react, non-fungible tokens, virtual asset, wallet",
  desc: " The world’s first virtual world of impact ",
};

export default Meta;
