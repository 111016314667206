const footerMenuList = [
  {
    id: 1,
    title: "Marketplace",
    diffClass: "md:col-start-7",
    list: [
      // {
      //   id: 1,
      //   href: "#",
      //   text: "Impact Network",
      // },
      {
        id: 2,
        href: "/campaigns",
        text: "Campaigns",
      },
      {
        id: 3,
        href: "/brands",
        text: "Brands",
      },
      {
        id: 4,
        href: "/causes",
        text: "Causes",
      },
      {
        id: 5,
        href: "/influencer",
        text: "Influencers",
      },
      {
        id: 6,
        href: "#",
        text: "",
      },
    ],
  },
  {
    id: 2,
    title: "Company",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/impact-token-marketplace",
        text: "Explore",
      },
      {
        id: 2,
        href: "/about",
        text: "About",
      },
      {
        id: 3,
        href: "/contact",
        text: "Contact Us",
      },
      // {
      //   id: 4,
      //   href: "#",
      //   text: "Our Blog",
      // },
      // {
      //   id: 5,
      //   href: "#",
      //   text: "FAQs",
      // },
    ],
  },
  {
    id: 3,
    title: "Account",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/sign-in",
        text: "Sign In",
      },
      {
        id: 2,
        href: "/sign-up",
        text: "Sign Up",
      },
      {
        id: 3,
        href: "/forget-password",
        text: "Forget",
      },
      {
        id: 4,
        href: "/create",
        text: "Create",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://twitter.com/impactoverse?s=21&t=724Qr7Mq2mqAnBIVRDYe4w",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.discord.com",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com/impactoverse?igshid=YmMyMTA2M2Y%3D",
    text: "instagram",
  },
  {
    id: 5,
    href: "https://www.tiktok.com/@impactoverse/",
    text: "tiktok",
  },
];

export { footerMenuList, socialIcons };
