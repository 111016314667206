import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { getAllNftPaginate } from "../services/campaign";
export const baseURL = `${process.env.BASE_URL}/api`;

const initialState = {
  campaignNft: [],
  cloneCampaignNft: [],
  isLoading: true,
  isError: true,
};
export const fetchGetAllNftPaginate = createAsyncThunk(
  "fetchGetAllNftPaginate",
  async (action) => {
    const { id, offset, isSold, All } = action;
    const res = await fetch(
      `${baseURL}/app/impact-associated-assets/${id}?limit=12&offset=${offset}&isSold=${isSold}&all=${All}`
    );
    return res?.json();
  }
);
export const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    actionfilterStatus: (state, action) => {
      switch (action.type) {
        case "api/actionfilterStatus":
          if (action.payload === "Availble") {
            return {
              ...state,
              campaignNft: {
                ...state.campaignNft,
                campaigns: state.campaignNft?.campaigns?.filter(
                  (item) => item.viewsCount === 0
                ),
              },
            };
          } else if (action.payload === "Sold") {
            return {
              ...state,
              campaignNft: {
                ...state.campaignNft,
                campaigns: state.campaignNft?.campaigns?.filter(
                  (item) => !item.isSold
                ),
              },
            };
          } else if (action.payload === "All") {
            return (state.campaignNft = state.campaignNft);
          }
        // return state;
        default:
          return state;
      }
    },

    campaignFilter: (state, action) => {
      const filteredCampaignNft = state.cloneCampaignNft?.campaigns?.filter(
        (product) => {
          if ((action.payload.status !== "All") === true) {
            const minCondition = action.payload.min
              ? product.priceUsd >= action.payload.min
              : true;
            const maxCondition =
              action.payload.max > 0
                ? product.priceUsd <= action.payload.max
                : true;
            const status =
              action.payload.status === "Sold"
                ? product.isSold
                : action.payload.status === "Available"
                ? !product.isSold
                : true;
            return minCondition && maxCondition && status;
          } else return true;
        }
      );
      state.campaignNft = {
        ...state.campaignNft,
        campaigns: filteredCampaignNft,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetAllNftPaginate.pending, (state, action) => {
      state.isLoading = true;
      state.campaignNft = [];
    });
    builder.addCase(fetchGetAllNftPaginate.fulfilled, (state, action) => {
      let tempArray = [];
      state.isLoading = false;
      let data = action.payload;
      if (data.success) {
        const newObj = {
          count: data?.assetCount || {},
          campaigns: data?.impactAssets,
        };
        tempArray.push(newObj);
        // console.log(tempArray[0]);
        state.campaignNft = tempArray[0];
        state.cloneCampaignNft = tempArray[0];
      } else {
        console.error("API request failed:", data.error);
        state.campaignNft = [];
      }
    });
    builder.addCase(fetchGetAllNftPaginate.rejected, (state, action) => {
      state.isError = true;
      state.campaignNft = [];
    });
  },
});

// Action creators are generated for each case reducer function
export const { stateCampaignNft, actionfilterStatus, campaignFilter } =
  apiSlice.actions;

export default apiSlice.reducer;
